$hamburger-padding-x           : .375rem;
$hamburger-padding-y           : .375rem;
$hamburger-layer-width         : 40px;
$hamburger-layer-height        : 4px;
$hamburger-layer-spacing       : 9px;
$hamburger-layer-color         : #fff;
$hamburger-layer-border-radius : 0;
$hamburger-hover-opacity       : 0.7 !default;
$hamburger-active-layer-color  : $light;
$hamburger-active-hover-opacity: .7;
