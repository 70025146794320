$primary: #B7B7A4;
$secondary: #262626;
$dark: #262626;
$light: #fff;
$yellow: #FFE800;
$cyan: #0090DF;
$red: #FF6761;


$success: #00ff00;
$info: $cyan;
$warning: $yellow;
$danger: $red;

$link-hover-decoration: none;
$link-decoration: none;

$font-family-sans-serif: 'Poppins', sans-serif;
