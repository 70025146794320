a {
    color: $light;
    text-decoration: none;
    transition: all .5s;
    &:hover {
        color: $primary;
        text-decoration: underline;
        i {
            text-decoration: none;
        }
    }
}

.va-text-yellow {
    color: $yellow !important;
}

.va-text-dark {
    color: $dark !important;
}

.va-text-primary {
    color: $primary !important;
}

/* Button Zertifikat
*/
.btn-download,
.btn-valid {
    background-color: $primary;
    border: 2px solid $primary;
    color: $dark;
    border-radius: 19px;
    transition: .4s;    
    &:hover {
        background-color: $dark;
        border: 2px solid $primary;
        color: $primary;
    }
}

.responsive-h1 {
    font-size: 2.5rem;
    font-size: clamp(1.75rem, 5vw, 3.5rem);
}

.transition-1 {
    transition: all 0.3s ease-in-out;
}

.btn-link {
    color: $light;
    &:hover {
        color: $primary;
    }
}