/* You can add global styles to this file, and also import other style files */
@import 'parts/fonts';

@import 'variables'; // überschreibt die default variablen von bootstrap
@import "bootstrap-icons/font/bootstrap-icons";
@import 'bootstrap/scss/bootstrap.scss';

@import 'parts/hamburgers'; // überschreibt die default variablen von hamburgers
@import 'hamburgers/_sass/hamburgers/hamburgers.scss';

@import 'parts/utility';

body {
    color: $light;
    background-color: $secondary;
}
